<template>
  <a-modal
    :visible="visible"
    title="请选择登录身份"
    :footer="null"
    :closable="change"
    @cancel="closeModal"
    :mask-closable="false"
  >
    <a-form :form="form" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }">
      <a-form-item v-for="{ label, decorator, ...props } in formFields" :key="decorator[0]" :label="label">
        <ayg-select v-decorator="decorator" v-bind="{ ...props }" />
      </a-form-item>
    </a-form>
  </a-modal>
</template>
<script>
import { mapMutations, mapState } from 'vuex'
import {
  setSelectedChannel,
  setSelectedAgent,
  getSelectedChannel,
  getProviders,
  getChannels,
  setChosen,
  setAgents,
  getSelectedAgent
} from '@/utils/auth'
import Cookies from 'js-cookie'

const initialValueEnum = {
  'console-agent': {
    subject: getSelectedChannel, // sales端的企业id为渠道商id
    obj: getSelectedAgent
  },
  'console-provider': {
    subject: getSelectedChannel
  }
}

export default {
  inject: ['reload'],
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    change: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showObject: false, // 显示选择操作对象
      form: this.$form.createForm(this, {
        onValuesChange: (props, value) => {
          if (value.subject) {
            this.operateObjects = []
            this.handleSubjectChange(value.subject)
          }
          if (value.obj) {
            this.handleObjChange(value.obj)
          }
        }
      }),
      operateObjects: [], // 操作对象
      initialValue: {}
    }
  },
  computed: {
    ...mapState(['platformType', 'currentChosen']),
    userProfiles() {
      const platfumStorageEnum = {
        'console-provider': getProviders,
        'console-agent': getChannels
      }
      if (!(this.platformType in platfumStorageEnum)) return []
      return platfumStorageEnum[this.platformType].call() || []
    },
    formFields() {
      const subjectItem = {
        label: '登录企业',
        decorator: [
          'subject',
          {
            rules: [
              {
                required: true,
                message: '请选择登录企业'
              }
            ],
            initialValue: this.initialValue.subject
          }
        ],
        data: this.userProfiles.map((el) => ({
          ...el,
          label: el.subjectName || el.name,
          value: el.id
        })),
        props: {
          label: 'label',
          value: 'value'
        },
        disabled: this.userProfiles?.length < 2,
        placeholder: '请选择登录企业',
        style: 'width: 100%'
      }
      const objectItem = {
        label: '操作对象',
        decorator: [
          'obj',
          {
            rules: [
              {
                required: true,
                message: '请选择操作对象'
              }
            ],
            initialValue: this.initialValue.obj
          }
        ],
        placeholder: '请选择操作对象',
        data: this.operateObjects,
        props: {
          label: 'label',
          value: 'value'
        },
        style: 'width: 100%'
      }
      const fields = [subjectItem]
      if (this.showObject) {
        return [...fields, objectItem]
      }
      return fields
    }
  },
  watch: {
    async visible(nv) {
      if (nv) {
        this.showObject = false
        const subject = Number(initialValueEnum[this.platformType]?.subject()) || undefined
        this.initialValue = {
          subject: undefined,
          obj: undefined
        }
        const industry = this.userProfiles.find((el) => el.id === subject)
        if (industry) {
          this.initialValue = {
            subject,
            obj: undefined
          }
          const { id, subjectType } = industry
          if (subjectType === 'admin') {
            await this.queryAgentList()
          }
          if (this.change) {
            this.handleObjChange(id)
          }
        }
      }
    }
  },
  methods: {
    ...mapMutations(['updateState']),
    // 选择企业
    handleSubjectChange(value) {
      this.form.resetFields(['obj'])
      const subject = this.userProfiles.find((el) => el.id === value)
      const enums = {
        'console-agent': this.agentLogic,
        'console-provider': this.providerLogic
      }
      const func = enums[this.platformType]
      func(subject)
    },
    // 选择操作对象
    handleObjChange(objId) {
      const object = this.operateObjects.find((el) => el.value === objId)
      const enums = {
        'console-agent': this.agentObjLogic
      }
      const func = enums[this.platformType]
      func(object)
    },
    // 代理商平台选择操作对象后
    agentObjLogic(object) {
      if (!object) return
      const { platformType } = this
      const { agentId, agentName } = object || {}
      const currentChosen = { id: agentId, name: agentName, platformType }
      const headerEnums = {
        id: `x-sec-subject-${platformType.split('-')[1]}-id`,
        name: `x-sec-subject-${platformType.split('-')[1]}-name`
      }
      setSelectedAgent(agentId, agentName)
      setChosen(currentChosen)
      this.updateState({
        name: 'currentChosen',
        value: currentChosen
      })
      this.setHeaderConfig({
        [headerEnums.id]: agentId,
        [headerEnums.name]: encodeURI(agentName)
      })
      this.refresh()
    },
    agentSubjectLogic(id, employeeId, name, subjectId) {
      this.commonCookie({
        id,
        employeeId,
        name,
        subjectId
      })
      this.updateState({
        name: 'selectedChannel',
        value: id
      })
      setSelectedChannel(id)
    },
    // 代理商平台逻辑
    agentLogic(subject) {
      const { subjectType, id, employeeId, subjectId, subjectName } = subject
      this.agentSubjectLogic(id, employeeId, subjectName, subjectId)
      if (subjectType === 'agent') {
        this.showObject = false
        // 直接登陆

        this.agentObjLogic({ agentId: subjectId, agentName: subjectName })
      } else {
        this.queryAgentList()
      }
    },
    // 服务商平台逻辑
    providerLogic(subject) {
      // 服务商直接选择企业
      this.showObject = false
      const { platformType } = this
      const { id, employeeId, name, subjectName, subjectId } = subject || {}
      if (!subject) return
      const currentChosen = { id, name: subjectName || name, platformType }
      this.commonCookie({
        id,
        employeeId,
        name: subjectName || name,
        subjectId
      })
      setSelectedChannel(id)
      setChosen(currentChosen)
      this.updateState({
        name: 'currentChosen',
        value: currentChosen
      })
      this.refresh()
    },
    async queryAgentList() {
      // 请求代理商列表
      const { data } = await this.$post('/api/agent-bff/common/permission/agent-company-list')
      this.operateObjects = (data || []).map((el) => ({
        ...el,
        label: el.agentName,
        value: el.agentId
      }))
      const subject = Number(initialValueEnum[this.platformType].subject()) || undefined
      const obj = Number(initialValueEnum[this.platformType].obj()) || undefined
      const index = this.operateObjects.findIndex((el) => Number(el.value) === obj)
      this.initialValue = {
        subject,
        obj: index > -1 ? obj : undefined
      }
      this.showObject = true
      setAgents(this.operateObjects)
      if (!this.visible) {
        // 登录页做判断
        const formerSelectedIsIncluded = index > -1
        this.operateObjects.length > 1 && !formerSelectedIsIncluded && this.$emit('update:visible', true)
        ;(this.operateObjects.length === 1 || formerSelectedIsIncluded) &&
          this.agentObjLogic(this.operateObjects[index > -1 ? index : 0])
        if (!this.operateObjects.length) {
          if (this.userProfiles.length < 2) {
            this.$notification.warning({
              description: '',
              message: '抱歉，当前账户未分配代理商权限！'
            })
            this.updateState({ name: 'loginLoading', value: false })
          } else if (this.userProfiles.length > 1) {
            this.$emit('update:visible', true)
          }
        }
      }
    },
    async checkNullValue(sourceObj) {
      const result = {}
      await Object.keys(sourceObj).forEach((el) => {
        sourceObj[el] &&
          sourceObj[el] !== 'null' &&
          Object.assign(result, {
            [el]: sourceObj[el]
          })
      })
      return result
    },
    async setHeaderConfig(config) {
      const headerConfig = JSON.parse(localStorage.getItem('headerConfig') || '{}')

      localStorage.setItem(
        'headerConfig',
        JSON.stringify({
          ...headerConfig,
          ...config,
          platformType: this.platformType
        })
      )
    },
    // 各端公有的cookie
    commonCookie({ id, employeeId, name, subjectId }) {
      const { platformType } = this
      const subjectAffix = platformType.split('-')[1]
      window.localStorage.setItem('userProfiles', id)
      Cookies.set('redash-profile', id, { domain: '.aiyuangong.com' })
      Cookies.set('x-sec-profile', id)
      Cookies.set('x-sec-employee', employeeId)
      if (platformType === 'console-provider') {
        localStorage.removeItem('headerConfig')
      } else {
        const headerEnums = {
          id: `x-sec-subject-${subjectAffix}-id`,
          name: `x-sec-subject-${subjectAffix}-name`
        }
        this.setHeaderConfig({
          'x-sec-profile': id,
          'x-sec-employee': employeeId,
          [headerEnums.id]: subjectId,
          [headerEnums.name]: encodeURI(name)
        })
      }
    },
    refresh() {
      this.form.resetFields()
      this.$emit('update:visible', false)
      this.reload()
    },
    closeModal() {
      const { subject, obj } = this.initialValue
      const currentSubject = this.userProfiles.find((el) => el.id === subject)
      this.platformType === 'console-agent' && this.agentDefaultSetting(currentSubject, obj)
      this.platformType === 'console-provider' && this.providerLogic(currentSubject)
    },
    async agentDefaultSetting(currentSubject, obj) {
      const { id, employeeId, subjectType, subjectId, subjectName } = currentSubject
      this.agentSubjectLogic(id, employeeId, subjectName, subjectId)
      if (!obj) {
        // 那subject就是console-agent类型
        subjectType === 'agent' && this.agentObjLogic({ agentId: subjectId, agentName: subjectName })
      } else {
        // obj为agent
        let currentAgent = this.operateObjects.find((el) => Number(el.value) === obj)
        if (!currentAgent) {
          // 重新请求agent列表
          await this.queryAgentList()
          currentAgent = this.operateObjects.find((el) => Number(el.value) === obj)
        }
        currentAgent && this.agentObjLogic(currentAgent)
      }
    }
  }
}
</script>
