<template>
  <div :style="systemWatermarkStyle" class="system-watermark" />
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState({
      userInformation: 'userInformation'
    }),
    systemWatermarkStyle() {
      const canvas = this.getWaterMarkCanvas({
        contentList: [this.userInformation.name, process.env.VUE_APP_ENV],
        fontFamily:
          '-apple-system,BlinkMacSystemFont,Segoe UI,PingFang SC,Hiragino Sans GB,Microsoft YaHei,Helvetica Neue,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol',
        fontSize: 16
      })
      return {
        backgroundImage: `url(${canvas.toDataURL('image/png')})`,
        backgroundPosition: 'center',
        backgroundRepeatX: 'repeat',
        ...this.watermarkStyle
      }
    }
  },
  props: {
    watermarkStyle: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    getWaterMarkCanvas({ contentList, fontFamily = 'Arial, sans-serif', fontSize = 16 }) {
      const canvas = document.createElement('canvas').getContext('2d')
      const arcNum = (30 * Math.PI) / 180
      canvas.canvas.width = 240
      canvas.canvas.height = 190
      canvas.font = `${fontSize}px ${fontFamily}`
      canvas.fillStyle = '#666'
      canvas.globalAlpha = 0.1
      canvas.save()
      canvas.textAlign = 'center'
      canvas.translate(100, 100 * (1 + Math.sin(arcNum)))
      canvas.rotate((-30 * Math.PI) / 180)
      canvas.fillText(contentList.join('@'), 0, 0)
      canvas.restore()
      return canvas.canvas
    }
  }
}
</script>

<style lang="scss" scoped>
.system-watermark {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99999;
  pointer-events: none;
}
</style>
