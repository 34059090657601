import Vue from 'vue'
import fetch from 'isomorphic-fetch'
import '@ayg/hm-plugin/dist/hmPlugin.css'
import 'ant-design-vue/dist/antd.less'
import { registerMicroApps, runAfterFirstMounted, start } from 'qiankun'
import antd from './utils/ant-design'
import { checkCode, getHeaders } from './request/index'
import router from './router'
import hmPlugin from '@ayg/hm-plugin'
import store from './store'
import App from './App.vue'
import bus from './utils/bus'
import { apps, appArray } from './apps'
import whiteList from '@/utils/whiteList'
import * as packageJson from '../package.json'
import '@/utils/injectInfo' // 探针注入，设置信息
import '@/style/element-variables.scss';

const { dependencies } = packageJson
window.___qiankun___version___ = dependencies.qiankun
console.log(window.___qiankun___version___)

window.myBus = bus
Vue.config.productionTip = false

Vue.use(antd)
Vue.use(hmPlugin, {
  network: {
    requestHandler: (config) => {
      config.headers = {
        ...config.headers,
        ...getHeaders(),
      }
      return config
    },
    requestError: (error) => Promise.reject(error),
    responseHandler: (response) => checkCode(response.data),
    // responseError: (error) => checkCode(error.response.data),
  },
})

Vue.prototype.$get = Vue.prototype.$hmUtils.network.get
Vue.prototype.$post = Vue.prototype.$hmUtils.network.post
Vue.prototype.$report = Vue.prototype.$hmUtils.report
Vue.prototype.$registeredApps = appArray
Vue.prototype.$rootState = Vue.observable(store.state)
Vue.prototype.$rootCommit = store.commit

Vue.prototype.$checkPermissions = (permissionsList, url) => {
  if (permissionsList) {
    return url in permissionsList
  }
  return false
}

const accessDenied = (path) => {
  // 新权限系统会返回当前登录账户的所有页面权限，不包含的页面显示403，这期只作admin端
  const { permissions } = store.state || {}
  return store.platformType !== 'console-admin' || path in permissions
}

// 珍贵的路由守卫😉
router.beforeEach((to, from, next) => {
  const { path, name } = to
  const subApp = path.split('/')[1].replace('micro-', '')
  // 白名单内路由不鉴权, 不显示侧边栏、顶部栏
  store.commit('updateState', {
    name: 'inWholeWhiteList',
    value: whiteList.whole.includes(path),
  })
  // 不带路径参数的时候不跳去404
  const unHandlePaths = whiteList.whole.concat(['/'])
  if (
    appArray.includes(subApp)
    || ['404', '403'].includes(name)
    || unHandlePaths.includes(path)
    || !accessDenied(path)
  ) {
    next()
  } else {
    next({ name: '404' })
  }
})

let vueApp = null

function render({ appContent, loading }) {
  if (!vueApp) {
    vueApp = new Vue({
      el: '#container',
      router,
      store,
      data() {
        return {
          content: appContent,
          loading,
        }
      },
      render(h) {
        return h(App, {
          props: {
            content: this.content,
            loading: this.loading,
          },
        })
      },
    })
  } else {
    vueApp.content = appContent
    vueApp.loading = loading
  }
}

render({
  loading: true,
})

let times = 0

// support custom fetch see: https://github.com/kuitos/import-html-entry/blob/91d542e936a74408c6c8cd1c9eebc5a9f83a8dc0/src/index.js#L163
const request = (url) => new Promise((resolve) => {
  fetch(url, {
    referrerPolicy: 'origin-when-cross-origin',
  })
    .then((res) => {
      resolve(res)
    })
    .catch(() => {
      console.log(`获取不到子应用${times}次`)
      if (times < 3) {
        times += 1
        request(url)
      } else {
        Vue.prototype.$info({
          title: '网络出错',
          content: '对不起，网络似乎开小差了，请刷新重试',
        })
      }
    })
})

registerMicroApps(
  apps(render, {
    rootState: vueApp.$store.state,
    rootCommit: vueApp.$store.commit,
  }),
  {
    beforeLoad: [
      (app) => {
        console.log('before load', app)
      },
    ],
    beforeMount: [
      (app) => {
        console.log('before mount', app)
      },
    ],
    afterMount: [
      (app) => {
        // 产品需求用工项目要折叠菜单
        store.commit('updateState', {
          name: 'menuCollapsed',
          value: app.name === 'micro-asr',
        })
      },
    ],
    afterUnmount: [
      (app) => {
        console.log('after unload', app)
        app.render({
          appContent: '',
          loading: false,
        })
      },
    ],
  },
  {
    fetch: request,
  },
)

// setDefaultMountApp(defaultApp) // 设置默认加载的子项目
runAfterFirstMounted(() => console.info('first app mounted'))

start({
  prefetch: false,
  fetch: request,
  jsSandbox: false, // 沙箱不可开，开启之后会使子项目间js相互污染，出现职业生涯中不能承受的错误
}) // prefetch为预加载，可根据实际使用情况开关。
