import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch((err) => err)
}

const router = new VueRouter({
  routes: [{
    path: '/',
    name: 'home',
  }, {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/client-login.vue'),
    meta: {
      title: '登录',
    },
  }, {
    path: '/policy_privacy',
    name: 'privacy',
    component: () => import('@/views/policy/privacy.vue'),
    meta: {
      title: '隐私政策',
    },
  }, {
    path: '/guide',
    name: 'guide',
    component: () => import('@/views/browser.vue'),
    meta: {
      title: '',
    },
  }, {
    // micro-开头的页面都导航到main，but有缺漏，子项目不存在的页面主项目无法得知，需开启qiankun的沙箱
    path: '/micro-*',
    name: 'main',
    component: () => import('@/views/main.vue'),
  }, {
    path: '*',
    name: '404',
    component: () => import('@/views/pageUnfound.vue'),
  }, {
    path: '*',
    name: '403',
    component: () => import('@/views/pageUnauthorized.vue'),
  }],
  mode: 'history',
  scrollBehavior: () => ({
    // 滚动条滚动的行为，不加这个默认就会记忆原来滚动条的位置，历史模式可用，hash无效
    y: 0,
  }),
})

export default router
